import { marked } from "marked";

import { blogMarked } from "./elements/blog";
import { walkHeading } from "./elements/heading";
import { highlightMarked } from "./elements/highlight";
import { renderImageMarked } from "./elements/image";
import { linkMarked } from "./elements/link";
import { pensionProviderMarked } from "./elements/pensionProvider";
import { renderTableCellMarked } from "./elements/tables";
import { typeformMarked } from "./elements/typeform";
import { videoMarked } from "./elements/video";
import { Token } from "./types";

const tokenizer = {
  html(): false {
    return false;
  },
};

marked.Renderer.prototype.image = renderImageMarked;
marked.Renderer.prototype.tablecell = renderTableCellMarked;

const walkTokens = (token: Token) => {
  walkHeading(token);
};

// Don't wrap these elements in paragraphs
marked.Renderer.prototype.paragraph = (text) => {
  if (
    text.startsWith("<img") ||
    text.startsWith("<figure>") ||
    text.startsWith("<pension")
  ) {
    return text + "\n";
  }

  return "<p>" + text + "</p>\n";
};

marked.use({
  breaks: true,
  extensions: [
    highlightMarked,
    blogMarked,
    linkMarked,
    pensionProviderMarked,
    typeformMarked,
    videoMarked,
  ],
  tokenizer,
  walkTokens,
});

export const parseMarkdown = (value?: string) =>
  marked.parse(
    value && value.trim().length > 0
      ? value.replace(/€ /g, "€&nbsp;")
      : "<p></p>"
  );
