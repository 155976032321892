import { Element } from "domhandler";
import { marked } from "marked";
import type { Descendant, Node, Text } from "slate";
import type { RenderElementProps } from "slate-react/dist/components/editable";

/* eslint-disable @typescript-eslint/no-unused-vars */
export import TokenizerExtension = marked.TokenizerExtension;
export import RendererExtension = marked.RendererExtension;
export import MarkedExtension = marked.TokenizerAndRendererExtension;
export import Tokens = marked.Tokens;
export import Token = marked.Token;
/* eslint-enable @typescript-eslint/no-unused-vars */

export type HTMLParser = (
  el: Element,
  children: Descendant[]
) => Partial<Descendant>;

export interface MarkdownSerializerOptions {
  ignoreParagraphNewline?: boolean;
  listDepth?: number;
  parentType?: string;
}

export type MarkdownSerializer<T extends CustomElement = CustomElement> = (
  chunk: T,
  children: string,
  opts: MarkdownSerializerOptions
) => string;

export interface RenderElement<P extends CustomElement = CustomElement>
  extends Omit<RenderElementProps, "element"> {
  element: P;
  textEditor?: boolean;
}

export enum MarkType {
  inlineCode = "inlineCode",
  strong = "strong",
  emphasis = "emphasis",
  strikeThrough = "strikeThrough",
}

export enum BlockType {
  quote = "quote",
  code = "code",
  paragraph = "paragraph",
  olList = "olList",
  ulList = "ulList",
  table = "table",
  tableBody = "table-body",
  tableHCell = "table-head-cell",
  tableHead = "table-head",
  tableRow = "table-row",
  tableCell = "table-cell",
}

export enum ElementType {
  headingOne = "headingOne",
  headingTwo = "headingTwo",
  headingThree = "headingThree",
  image = "image",
  link = "link",
  listItem = "listItem",
  thematicBreak = "thematic_break",
}

export type NodeType = MarkType | BlockType | ElementType | ExtensionType;

export const HeadingTypes = [
  ElementType.headingOne,
  ElementType.headingTwo,
  ElementType.headingThree,
];

export const TableCellTypes: NodeType[] = [
  BlockType.tableCell,
  BlockType.tableHCell,
];

export const TableSectionTypes: NodeType[] = [
  BlockType.tableBody,
  BlockType.tableHead,
];

export const TableTypes: NodeType[] = [
  BlockType.table,
  ...TableSectionTypes,
  BlockType.tableRow,
  ...TableCellTypes,
];

export const isTableNode = (node?: Node): node is CustomElement =>
  (isElement(node) && TableTypes.includes(node?.type)) || false;

export const isTableCell = (node?: Node): node is CustomElement =>
  (isElement(node) && TableCellTypes.includes(node?.type)) || false;

export enum ExtensionType {
  blog = "blog",
  highlight = "highlight",
  pensionProvider = "pensionProvider",
  typeform = "typeform",
  video = "video",
}

export const VoidElements: NodeType[] = [
  ElementType.thematicBreak,
  ElementType.image,
  ExtensionType.blog,
  ExtensionType.pensionProvider,
  ExtensionType.typeform,
  ExtensionType.video,
];

type CustomElement = { children: Descendant[]; id?: string; type: NodeType };
type MarkTypeMap = {
  [key in MarkType]: undefined | true | string;
};
export type CustomText = Partial<MarkTypeMap> & { text: string };

declare module "slate" {
  interface CustomTypes {
    Element: CustomElement;
    Text: CustomText;
  }
}

export const isElement = (node?: Node): node is CustomElement =>
  typeof (node as CustomElement)?.type === "string";

export const isLeafNode = (node?: Node): node is Text =>
  typeof (node as Text)?.text === "string";

export const isLink = (node?: Node): node is LinkNode =>
  isElement(node) && node?.type === ElementType.link;

export const LIST_TYPES: NodeType[] = [BlockType.olList, BlockType.ulList];

export const isList = (node?: Node): node is ListNode =>
  (isElement(node) && LIST_TYPES.includes(node?.type)) || false;

export interface CodeBlockNode extends CustomElement {
  language: string | undefined;
  type: BlockType.code;
}

export interface ListNode extends CustomElement {
  type: ElementType.listItem;
}

export enum LinkStyle {
  Link = "link",
  Primary = "primary",
  Secondary = "secondary",
}

export interface LinkNode extends CustomElement {
  href: string;
  style: LinkStyle;
  text?: string;
  title?: string;
  type: ElementType.link;
}

export interface HeadingNode extends CustomElement {
  id: string;
}

export interface ImageNode extends CustomElement {
  alt: string;
  caption?: string;
  src: string;
  type: ElementType.image;
}

export interface BlogNode extends CustomElement {
  slug: string;
  type: ExtensionType.blog;
}

export interface PensionProviderNode extends CustomElement {
  provider: string;
  type: ExtensionType.pensionProvider;
  url: string;
}

export interface TypeformNode extends CustomElement {
  formId: string;
  type: ExtensionType.typeform;
}

export interface TableHeadNode extends CustomElement {
  display: boolean;
  type: BlockType.tableHead;
}

export interface TableCellNode extends CustomElement {
  colSpan?: number;
  type: BlockType.tableCell | BlockType.tableHCell;
}

export interface VideoNode extends CustomElement {
  url: string;
}
