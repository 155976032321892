import { Td, Th, chakra } from "@chakra-ui/react";
import React from "react";
import { RenderElementProps } from "slate-react/dist/components/editable";

import { renderBlockquote } from "./elements/blockQuote";
import { renderBlog } from "./elements/blog";
import { renderHeading } from "./elements/heading";
import { renderHighlight } from "./elements/highlight";
import { renderImage } from "./elements/image";
import { renderLink } from "./elements/link";
import { renderPensionProvider } from "./elements/pensionProvider";
import {
  renderTable,
  renderTableBody,
  renderTableCell,
  renderTableHead,
  renderTableRow,
} from "./elements/tables";
import { renderTypeform } from "./elements/typeform";
import { renderVideo } from "./elements/video";
import { BlockType, ElementType, ExtensionType, RenderElement } from "./types";

export type Renderer = (props: RenderElement) => JSX.Element;
export type Renderers = {
  [type: string]: Renderer;
};

export const defaultRenderers: Renderers = {
  [ExtensionType.blog]: renderBlog,
  [BlockType.code]: ({ attributes, children }) => (
    <pre {...attributes}>
      <code>{children}</code>
    </pre>
  ),
  [BlockType.quote]: renderBlockquote,
  [BlockType.ulList]: ({ attributes, children }) => (
    <chakra.ul marginBottom="1em" paddingLeft="2em" {...attributes}>
      {children}
    </chakra.ul>
  ),
  [ElementType.headingOne]: renderHeading("2"),
  [ElementType.headingTwo]: renderHeading("3"),
  [ElementType.headingThree]: renderHeading("4"),
  [ElementType.image]: renderImage,
  [ElementType.link]: renderLink,
  [ElementType.listItem]: ({ attributes, children }) => (
    <li {...attributes}>{children}</li>
  ),
  [ElementType.thematicBreak]: ({ attributes }) => <hr {...attributes} />,
  [BlockType.olList]: ({ attributes, children }) => (
    <chakra.ol marginBottom="1em" paddingLeft="2em" {...attributes}>
      {children}
    </chakra.ol>
  ),
  [BlockType.paragraph]: ({ attributes, children }) => (
    <chakra.p marginBottom="1em" {...attributes}>
      {children}
    </chakra.p>
  ),
  [ExtensionType.highlight]: renderHighlight,
  [ExtensionType.pensionProvider]: renderPensionProvider,
  [BlockType.table]: renderTable,
  [BlockType.tableBody]: renderTableBody,
  [BlockType.tableCell]: renderTableCell(Td, false),
  [BlockType.tableHCell]: renderTableCell(Th, false),
  [BlockType.tableHead]: renderTableHead,
  [BlockType.tableRow]: renderTableRow,
  [ExtensionType.typeform]: renderTypeform,
  [ExtensionType.video]: renderVideo,
};

export const rendererForProps = (
  renderers: Renderers,
  props: RenderElement
) => {
  const { element } = props;

  const Renderer = renderers[element.type];
  if (!Renderer) {
    throw new Error(`Unknown type: ${element.type}`);
  }

  return Renderer;
};

export const renderElement = (props: RenderElementProps) => {
  const Renderer = rendererForProps(defaultRenderers, props);

  return <Renderer {...props} />;
};
